import * as SentrySvelte from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import type { Err } from '$lib/types';
import { dev } from '$app/environment';
import { CaptureConsole } from '@sentry/integrations';

if (!dev) {
	SentrySvelte.init({
		environment: dev ? 'development' : 'production',
		dsn: PUBLIC_SENTRY_DSN,
		integrations: [new CaptureConsole({ levels: ['warn', 'error'] })]
	});
}

export const handleError: HandleClientError = ({ error, event }) => {
	const err: Err = error as Err;
	const message = err.status === 404 ? 'Not found' : 'An unexpected error occurred.';

	const errorId = crypto.randomUUID();
	SentrySvelte.captureException(error, {
		contexts: { sveltekit: { event, errorId } }
	});

	return {
		message,
		errorId,
		status: err.status
	};
};
