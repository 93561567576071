import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/authorize/confirm": [3],
		"/authorize/error": [~4],
		"/dashboard": [~5],
		"/dashboard/edit/[id]": [~6],
		"/dashboard/submit": [~7],
		"/faqs": [8],
		"/ideas/[id]": [~9],
		"/learn": [10],
		"/policy": [11],
		"/signin": [~12],
		"/signup": [~13],
		"/terms": [14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';